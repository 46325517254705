// Import Packages
import * as React from 'react'
import * as ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import 'semantic-ui-css/semantic.min.css'

import ContactDetails from 'app/Contacts/Details'
import Contacts from 'app/Contacts/Dashboard'
import Dashboard from 'app/Dashboard'
import Events from 'app/Events'
import LeadDetails from 'app/Leads/Details'
import Leads from 'app/Leads/Dashboard'
import Logout from 'app/Logout'
import MailBox from 'app/MailBox'
import Navbar from 'app/Navbar'
import Offices from 'app/Offices'
import Onboarding from 'app/Onboarding'
import Reports from 'app/Reports'
import Settings from 'app/Settings'
import Tasks from 'app/Tasks'
import Teams from 'app/Teams'
import TransactionDashboard from 'app/Transactions/Dashboard'
import TransactionDetails from 'app/Transactions/Details'
import TransactionListing from 'app/Transactions/Listing'
import UserProfile from 'app/UserProfile'
import Users from 'app/Users'

import { AppState } from 'store/CombineReducers'
import { toggleNavigation } from 'store/Layout/Actions'
import { handleUserActivation } from './Mutations'

import { AppWrapper, Section } from './Styled'

import { getLoggedInUser, RestrictAccess, RouterRoles, Routes } from 'utils'

const {
  dashboard,
  events,
  mailbox,
  transactions,
  tasks,
  teams,
  profile,
  leads,
  contacts,
  offices,
  reports,
  settings,
  users
} = Routes.primary

const Confetti = require('react-confetti')

const logout = Routes.logout
const login = Routes.onboarding.root

interface Props extends RouteComponentProps<{}, {}> {
  setToggleNavBar: (isVisible: boolean) => void
  isNavBarVisible: boolean
  confetti: boolean
}

class App extends React.Component<Props> {
  public componentDidMount = async () => {
    // Creates Portal DOM Element to aid DND fixed positioning on drag elements
    const portal: HTMLDivElement = document.createElement('div')
    portal.id = '__dnd-portal'
    document.body.appendChild(portal)

    ReactGA.initialize('UA-158081690-1')

    toast.configure({
      autoClose: 8000,
      draggable: false
    })
    await this.handleRouteGuarding()
  }

  public componentDidUpdate = async (prev: Props) => {
    if (prev.location.pathname !== this.props.location.pathname) {
      await this.handleRouteGuarding()
    }
  }

  public render() {
    const { isNavBarVisible, confetti } = this.props
    return (
      <AppWrapper>
        {confetti && (
          <Confetti numberOfPieces={1500} recycle={false} width={window.innerWidth} height={window.innerHeight} />
        )}
        <ToastContainer />
        {isNavBarVisible && <Navbar />}
        <Section>
          <Switch>
            <Route path="/" exact={true} render={this.handleRedirect} />
            <Route path={dashboard.path} component={Dashboard} />
            <Route exact={true} path={transactions.path} component={TransactionDashboard} />
            <Route path={`${transactions.path}/details/:id/:subpage`} component={TransactionDetails} />
            <Route exact={true} path={contacts.path} component={Contacts} />
            <Route path={contacts.path} component={ContactDetails} />
            <Route exact={true} path={leads.path} component={Leads} />
            <Route path={leads.path} component={LeadDetails} />
            <Route path={tasks.path} component={Tasks} />
            <Route
              path={events.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.events} {...props}>
                  <Events />
                </RestrictAccess>
              )}
            />
            <Route
              path={teams.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.teams} {...props}>
                  <Teams />
                </RestrictAccess>
              )}
            />
            <Route
              path={`${profile.path}/:id`}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.userProfile} {...props}>
                  <UserProfile />
                </RestrictAccess>
              )}
            />
            <Route
              path={users.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.users} {...props}>
                  <Users />
                </RestrictAccess>
              )}
            />
            <Route
              path={reports.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.reports} {...props}>
                  <Reports />
                </RestrictAccess>
              )}
            />
            <Route
              path={settings.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.settings} {...props}>
                  <Settings {...props} />
                </RestrictAccess>
              )}
            />
            <Route
              path={offices.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.office} {...props}>
                  <Offices />
                </RestrictAccess>
              )}
            />
            <Route
              path={mailbox.path}
              render={props => (
                <RestrictAccess allowedRoles={RouterRoles.mailBox} {...props}>
                  <MailBox />
                </RestrictAccess>
              )}
            />
            <Route path={Routes.listing.path + Routes.listing.params} component={TransactionListing} />
            <Route path={Routes.listing.path} component={TransactionListing} />
            <Route path={login} component={Onboarding} />
            <Route path={logout.path} component={Logout} />
            <Redirect to={dashboard.path} />
          </Switch>
        </Section>
      </AppWrapper>
    )
  }

  private handleRouteGuarding = async () => {
    const $crisp = window['$crisp']
    const { setToggleNavBar, location: { pathname }} = this.props
    const isAppPage = !pathname.includes(Routes.listing.path)
    const isPublicPage = pathname.includes(login) || !isAppPage
    const user: any = await getLoggedInUser({ fromCache: true })

    if (user && !isPublicPage) {
      setToggleNavBar(true)
    } else {
      setToggleNavBar(false)
    }

    if (isAppPage) {
      $crisp.push(['do', 'chat:show'])
    } else {
      $crisp.push(['do', 'chat:hide'])
    }

    if (!user && !isPublicPage) {
      this.props.history.push(login)
      return
    } else if (!user) {
      return
    }

    if (!isPublicPage && user.role === 'ADMIN' && user.office.length === 0) {
      this.props.history.push(login + Routes.onboarding.teamSize.path)
      return
    }

    if (!isPublicPage && user.status && user.status !== 'Active') {
      await handleUserActivation()
      await getLoggedInUser()
    }
  }

  private handleRedirect = () => <Redirect to={Routes.onboarding.root} />
}

const mapStateToProps = (state: AppState) => ({
  confetti: state.transactions.confetti,
  isNavBarVisible: state.layout.navigation.visible
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setToggleNavBar: toggleNavigation
    }
  )(App)
)
