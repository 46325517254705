import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { RestLink } from 'apollo-link-rest'
import { createUploadLink } from 'apollo-upload-client'
import gql from 'graphql-tag'
import updateCapSettingsLocals from 'resolvers/updateCapSettingResolver'
import updateTransactionSettingLocals from 'resolvers/updateTransactionResolver'

// TODO: fragmentMatcher is used to supress warnings that break queries with Union/Interface types
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
})

export const cacheData = new InMemoryCache({ fragmentMatcher })

const restLink = new RestLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_URL
})

function stripTypenames(obj: any, propToDelete: string) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypenames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, '__typename')
  }
  return forward ? forward(operation) : null
})

const typeDefs = gql`
  extend type Query {
    expectedCommission: Int
    propertyFee: Int
  }
  extend type Office {
    isChecked: Boolean
  }
  extend type Team {
    checked: Boolean
  }
  extend type Lead {
    checked: Boolean
  }
  extend type User {
    isLoggedIn: Boolean
  }
  extend type Task {
    checked: Boolean
  }
`

const uploadLink = createUploadLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL
})

const client = new ApolloClient({
  cache: cacheData,
  link: from([removeTypenameMiddleware, restLink, uploadLink]),
  resolvers: {
    Lead: {
      checked: () => false
    },
    Mutation: {
      updateCapSetting: updateCapSettingsLocals,
      updateTransactionSetting: updateTransactionSettingLocals
    },
    Office: {
      isChecked: () => false
    },
    Task: {
      checked: () => false
    },
    Team: {
      checked: () => false
    },
    User: {
      isLoggedIn: (launch, args, { cache }) => {
        return false
      }
    }
  },
  typeDefs
})

cacheData.writeData({
  data: {
    checked: false,
    expectedCommission: 9000,
    isChecked: false,
    propertyFee: 300000
  }
})

export default client
